import { FC, useState } from "react";
import { money, Transaction } from "./App";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import sv from "date-fns/locale/sv";
import "react-datepicker/dist/react-datepicker.css";
import "./App.scss";

registerLocale("sv", sv);

const Row: FC<{
  transaction: Transaction;
  cumulative_sum: number;
  verified: boolean;
  handleChange: () => void;
}> = ({ transaction, cumulative_sum, verified, handleChange }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isVerified, setVerified] = useState(verified);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [date, setDate] = useState(new Date(transaction.date));
  const [isEditingAmount, setIsEditingAmount] = useState(false);
  const [isEditingDescription, setIsEditingDescription] = useState(false);

  const changeVerified = (v: boolean) => {
    fetch(`https://my.bytelabs.se/api/verified.php?`, {
      method: "POST",
      body: `id=${transaction.id}&verified=${v}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then(() => {
        setVerified(v);
        handleChange();
      })
      .catch((error) => console.error(error));
  };

  const changeDate = (date: Date) => {
    fetch(`https://my.bytelabs.se/api/date.php?`, {
      method: "POST",
      body: `id=${transaction.id}&date=${date.toLocaleDateString()}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then(() => {
        setDate(date);
        handleChange();
      })
      .catch((error) => console.error(error));
  };

  const changeAmount = (amount: number, profitAmount: number) => {
    fetch(`https://my.bytelabs.se/api/amount.php?`, {
      method: "POST",
      body: `id=${transaction.id}&amount=${amount}&profit_amount=${profitAmount}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then(() => handleChange())
      .catch((error) => console.error(error));
  };

  const changeDescription = (description: string) => {
    fetch(`https://my.bytelabs.se/api/description.php?`, {
      method: "POST",
      body: `id=${transaction.id}&description=${description}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then(() => handleChange())
      .catch((error) => console.error(error));
  };

  const deleteTransaction = () => {
    fetch(`https://my.bytelabs.se/api/delete.php?id=${transaction.id}`, {
      method: "GET",
    })
      .then(() => handleChange())
      .catch((error) => console.error(error));
  };

  const handleAmountChange = (newAmount: number) => {
    const newProfitAmount =
      transaction.profit_amount !== transaction.amount
        ? transaction.profit_amount
        : newAmount;
    changeAmount(newAmount, newProfitAmount);
    setIsEditingAmount(false);
  };

  const handleDescriptionChange = (newDescription: string) => {
    changeDescription(newDescription);
    setIsEditingDescription(false);
  };

  return (
    <tr
      onClick={() => setIsEditing(!isEditing)}
      className={`${isVerified ? "verified" : ""} ${
        isEditing ? "editing" : ""
      }`}
    >
      <td className="nowrap">
        {showDatePicker && (
          <div
            style={{
              position: "absolute",
              marginTop: "20px",
            }}
          >
            <DatePicker
              inline
              locale="sv"
              dateFormat="P"
              selected={date}
              onChange={(date) => {
                if (date) {
                  changeDate(date);
                  setShowDatePicker(false);
                }
              }}
            />
          </div>
        )}
        <div
          style={{
            cursor: isVerified ? "auto" : "pointer",
          }}
          onClick={() => !isVerified && setShowDatePicker(!showDatePicker)}
        >
          {date.toLocaleDateString()}
        </div>
      </td>
      <td>
        {isEditingDescription ? (
          <input
            type="text"
            style={{ border: 0, width: 200 }}
            defaultValue={transaction.description}
            autoFocus={true}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                handleDescriptionChange(e.currentTarget.value);
              }
            }}
            onBlur={(e) => {
              handleDescriptionChange(e.target.value);
            }}
          />
        ) : (
          <div
            className="description"
            onClick={() => {
              if (!verified) {
                setIsEditingDescription(true);
              }
            }}
          >
            {transaction.description}
          </div>
        )}
      </td>
      <td>
        {!isVerified && (
          <button className="delete-transaction" onClick={deleteTransaction}>
            🗑️
          </button>
        )}
      </td>
      <td className={`nowrap right ${transaction.amount < 0 ? "neg" : ""}`}>
        {isEditingAmount ? (
          <input
            type="text"
            className={`${transaction.amount < 0 ? "neg" : ""}`}
            style={{ textAlign: "right", border: 0, width: 100 }}
            defaultValue={transaction.amount}
            autoFocus={true}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                handleAmountChange(parseInt(e.currentTarget.value, 10));
              }
            }}
            onBlur={(e) => {
              handleAmountChange(parseInt(e.target.value, 10));
            }}
          />
        ) : (
          <div
            onClick={() => {
              if (!verified) {
                setIsEditingAmount(true);
              }
            }}
          >
            {money(transaction.amount)}
          </div>
        )}
        {transaction.profit_amount !== transaction.amount && !isEditingAmount && (
          <small>
            Resultatpåverkan:&nbsp;
            {money(transaction.profit_amount)}
          </small>
        )}
      </td>
      <td
        className={`nowrap mobilehide right ${cumulative_sum < 0 ? "neg" : ""}`}
      >
        {money(cumulative_sum)}
      </td>
      <td>
        <button
          className={isVerified ? "verification checked" : "verification"}
          onClick={() => changeVerified(!isVerified)}
        />
      </td>
    </tr>
  );
};

export default Row;
