import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  Title,
  Legend,
  PointElement,
  Tooltip,
  Filler,
  registerables as registerablesJS,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import { Transaction } from "./App";

ChartJS.register(...registerablesJS);
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Legend,
  Tooltip,
  Filler
);

interface MyChartProps {
  transactions: Transaction[];
}

export const MyChart = (props: MyChartProps) => {
  const { transactions } = props;

  const options = {
    responsive: true,
    animation: {
      duration: 100,
    },
    plugins: {
      legend: {
        display: false,
      },
      scales: {
        x: {
          stacked: true,
        },
        y: {
          ticks: {
            beginAtZero: true,
          },
        },
      },
    },
  };

  let cumulative_sum = 0;
  const data = {
    labels: transactions.map((t) => t.description),
    datasets: [
      {
        type: "bar" as const,
        label: "Transaktion",
        data: transactions.map((t) => t.amount),
        backgroundColor: transactions.map((t) =>
          t.amount < 0 ? "rgba(219, 42, 62, 1.0)" : "rgba(0, 203, 86, 1.0)"
        ),
      },
      {
        type: "line" as const,
        label: "Totalt",
        data: transactions.map((t) => {
          cumulative_sum = cumulative_sum + t.amount;
          return cumulative_sum;
        }),
        fill: true,
        backgroundColor: "rgba(40, 44, 52, 0.1)",
        borderColor: "rgba(40, 44, 52, 0.5)",
        borderWidth: 2,
        pointRadius: 0,
      },
    ],
  };

  return <Chart type="line" options={options} data={data} />;
};
