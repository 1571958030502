import { useEffect, useState } from "react";
import { MyChart } from "./Chart";
import Row from "./Row";
import "./App.scss";

function App() {
  const [transactions, setTransactions] = useState<Transactions>();
  const [selectedTransactions, setSelectedTransactions] = useState<
    Transaction[]
  >([]);
  const [year, setYear] = useState(2024);
  const [account, setAccount] = useState("BANK");

  useEffect(() => {
    if (transactions) {
      if (account === "BANK") {
        setSelectedTransactions(transactions.BANK);
      } else if (account === "KF") {
        setSelectedTransactions(transactions.KF);
      } else if (account === "VP") {
        setSelectedTransactions(transactions.VP);
      } else {
        setSelectedTransactions(
          transactions.BANK.concat(transactions.KF)
            .concat(transactions.VP)
            .sort(dateSort)
        );
      }
    }
  }, [account, transactions]);

  // Fetch transactions
  const getData = () => {
    fetch(`https://my.bytelabs.se/api/list.php?year=${year}`)
      .then((resp) => resp.json())
      .then((resp: Transactions) => {
        resp["BANK"] = resp["BANK"].sort(dateSort);
        resp["KF"] = resp["KF"].sort(dateSort);
        resp["VP"] = resp["VP"].sort(dateSort);
        setTransactions(resp);
      })
      .catch((error) => console.error(error));
  };

  const newTransaction = () => {
    fetch(`https://my.bytelabs.se/api/new.php`)
      .then(getData)
      .catch((error) => console.error(error));
  };

  const dateSort = (a: Transaction, b: Transaction) => {
    if (a.date === b.date) {
      return 0;
    }
    return a.date < b.date ? -1 : 1;
  };

  useEffect(() => {
    getData();
  }, [year]);

  const handleChange = () => getData();

  let cumulative_sum = 0;
  let turnover = 0;

  const years = [2020, 2021, 2022, 2023, 2024];
  const accounts = ["BANK", "KF", "VP", "TOTALT"];

  return (
    <div className="App">
      <header className="App-header">
        <h1>My Byte Labs</h1>
      </header>
      <div className="button-bar">
        <div className="button-selector">
          {years.map((y) => {
            return (
              <button
                key={y}
                className={y === year ? "selected" : ""}
                onClick={() => setYear(y)}
              >
                {y}
              </button>
            );
          })}
        </div>
        <div className="button-selector">
          {accounts.map((a) => {
            return (
              <button
                key={a}
                className={a === account ? "selected" : ""}
                onClick={() => setAccount(a)}
              >
                {a}
              </button>
            );
          })}
        </div>
      </div>
      <button className="create-transaction-button" onClick={newTransaction}>
        +
      </button>
      <div className="chart-wrapper">
        <MyChart transactions={selectedTransactions} />
      </div>
      {selectedTransactions && (
        <table>
          <tbody>
            {selectedTransactions.map((t: Transaction) => {
              cumulative_sum = cumulative_sum + t.amount;
              turnover = turnover + Math.max(t.profit_amount, 0);
              return (
                <Row
                  key={t.id}
                  transaction={t}
                  cumulative_sum={cumulative_sum}
                  verified={t.verified}
                  handleChange={handleChange}
                ></Row>
              );
            })}
            <tr>
              <th></th>
              <th>Omsättning</th>
              <th style={{ textAlign: "right" }}>{money(turnover)}</th>
              <th></th>
              <th></th>
            </tr>
            <tr>
              <th></th>
              <th>Vinst</th>
              <th style={{ textAlign: "right" }}>
                {money(
                  sum(
                    selectedTransactions.map(
                      (t: Transaction) => t.profit_amount
                    )
                  )
                )}
              </th>
              <th></th>
              <th></th>
            </tr>
            <tr>
              <th></th>
              <th>Skatt</th>
              <th style={{ textAlign: "right" }}>
                {money(
                  sum(
                    selectedTransactions.map(
                      (t: Transaction) => t.profit_amount
                    )
                  ) * 0.2
                )}
                <br />
                <small>
                  {money(
                    (sum(
                      selectedTransactions.map(
                        (t: Transaction) => t.profit_amount
                      )
                    ) *
                      0.2) /
                      12
                  )}
                  /mån
                </small>
              </th>
              <th></th>
              <th></th>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
}

export default App;

export interface Transactions {
  BANK: Transaction[];
  VP: Transaction[];
  KF: Transaction[];
}

export interface Transaction {
  id: string;
  date: Date;
  description: string;
  amount: number;
  profit_amount: number;
  verified: boolean;
}

export const money = (amount: number) =>
  new Intl.NumberFormat("sv-SE", {
    style: "currency",
    currency: "SEK",
    maximumFractionDigits: 0,
  }).format(amount);

export const sum = (amounts: number[]) =>
  amounts.reduce((sum, amount) => {
    return sum + amount;
  }, 0);
